// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// AdminLte
@import '~admin-lte/build/scss/adminlte';

// Skin
@import 'skin-ply';
