//Variables
@import "variables";

// login
.login-box {
    margin: auto !important;

    .login-logo {
        img {
            max-width: 150px;
        }
    }
}

.brand-link {
    border-bottom: none !important;

    .brand-image {
        max-height: 54px;
    }
}

.btn {
    &-primary {
        background-color: $primary;
        border: none;
        color: $grey;
    }
}

// sidebar
.sidebar-dark-primary {
    background-color: $grey;

    .sidebar {
        padding-top: 20px;
    }
}

.filter {
    ul {
    }
}

.select2 {
    &.select2-container {
        ul {
            li {
                .select2-selection__choice__display {
                    color: $grey;
                }

                button {
                    &.select2-selection__choice__remove {
                        border: none !important;
                        color: $grey;
                    }

                    &:hover {
                        background-color: transparent !important;
                    }
                }

                .select2-selection__choice__display {
                    padding-left: 18px !important;
                }
            }
        }

        .select2-search__field {
            border: none !important;
        }

        &.select2-container--open {
            border-color: #ced4da !important;
        }
    }

    &.select2-container--open {
        .selection {
            .select2-selection {
                &.select2-selection--multiple {
                    border-color: #ced4da !important;
                }
            }
        }
    }

    .select2-selection {
        border: 1px solid #ced4da !important;
    }
}

//forms
.repeating {
    .entry {
        input:not(:first-of-type) {
            margin-top: 10px;
        }

        .btn {
            margin: 10px 0px 10px 0px;
            padding: 0px 5px 0px 5px;
            height: 25px;
            width: 25px;
        }

        .btn-add {
        }

        .btn-remove {
        }
    }
}

.padding-20 {
    padding-left: 20px;
}

button {
    padding: 10px;
}

.margin-top-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.vsa-item__trigger {
    padding: 0 !important;
}

.vsa-item__trigger__icon.vsa-item__trigger__icon--is-default {
    transform: scale(0.5) !important;
}

a {
    color: $grey;

    &:active,
    &:focus,
    &:hover {
        color: $primary;
    }
}

nav[role="navigation"] {
    display: flex;
    justify-content: space-between;
}

textarea {
    min-height: 200px;
}

.ck-editor__editable_inline {
    min-height: 200px;
}

.alert {
    *:last-child {
        margin-bottom: 0;
    }
}

.handle {
    margin-right: 8px;
}

.select2-container {
    .select2-selection--multiple {
        height: auto !important;

        .select2-selection__choice:first-child {
            margin-left: 0;
        }
    }
    .select2-search--inline {
        .select2-search__field {
            min-height: 0;
        }
    }
}

.content-header {
    h1 {
        > small {
            margin-left: 4px;
            font-size: 14px;
            color: $grey;
        }
    }
}

.workout-status-badge {
    top: -0.25rem;
    position: relative;
}

.card .superset {
    background-color: #E9F5F0;
}

.wrapper {
    width: 100%;
    min-height: 100%;
    height: auto !important;
    position: absolute;
}

.main-exercise {
    border: 1px solid rgba(0, 0, 0, 0.125);
    background-color: #ced4da;
    padding: 0.75rem 1.25rem;
    margin: 10px 0px;

}

.swaps {
    margin:10px 0px;
}
